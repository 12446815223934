._3_Ozh1xIkutWoTIuxPhs4N {
  padding: 15px 20px;
  font: inherit;
  color: inherit;
  background: rgba(34, 34, 34, 0.05);
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  transition: background 200ms ease, color 200ms ease, box-shadow 200ms ease; }
  ._3_Ozh1xIkutWoTIuxPhs4N:hover {
    background: rgba(34, 34, 34, 0.15); }
  ._3_Ozh1xIkutWoTIuxPhs4N:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(1, 51, 112, 0.2); }

.zYyzgQGsB13aZPGf82gOL {
  background: #013370;
  color: white; }
  .zYyzgQGsB13aZPGf82gOL:hover {
    background: rgba(1, 51, 112, 0.8); }
  .zYyzgQGsB13aZPGf82gOL:focus {
    box-shadow: 0 0 0 2px rgba(1, 51, 112, 0.3); }
