._1Xl6uaYsn-DK1UxiW6-nf9 {
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin: 0 auto;
  max-width: 600px; }

._1CrPO69RUr0luv3PtPIin {
  font-weight: 500;
  font-size: 1.25rem; }

._1RrmByhPUsZ9ghgQxjZoEw {
  color: #DB2B39; }

._3yudxbxlY-7WflY9iJZ65J {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -15px; }
  ._3yudxbxlY-7WflY9iJZ65J > ._1WODe9Jj0hFW0hxjh8CWln {
    margin: 0 15px 30px; }

._1WODe9Jj0hFW0hxjh8CWln {
  margin-bottom: 30px;
  transition: opacity 200ms ease, filter 200ms ease; }

._240WQgFl9cbyDE-vcMuBfV {
  pointer-events: none;
  opacity: 0.5;
  filter: grayscale(100%); }

._2rIQBsCUx-EaGZA3RmMZu {
  margin-bottom: 15px; }

.L3tUQn3eEAeipRjIqE3jG {
  height: 1.25em;
  margin-top: -11.25px;
  margin-bottom: 15px;
  font-size: 0.9rem;
  color: rgba(34, 34, 34, 0.5); }

.-jAjvPCYBKVaa-gw-g7eA {
  color: #DB2B39;
  transition: opacity 200ms ease, height 200ms ease, margin 200ms ease; }

._31Jn8W8D3msPBYz1bVNL44 {
  height: 0;
  margin-bottom: 11.25px;
  opacity: 0; }
