.olD_OAs0JGFvWwjnCrBYi {
  margin-top: -43.2px;
  margin-left: -18px;
  width: 36px;
  height: 43.2px;
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.25)) drop-shadow(0 2px 5px rgba(0, 0, 0, 0.15));
  pointer-events: none;
  cursor: pointer;
  transform-origin: 50% 100%;
  transition: transform 100ms ease; }
  .olD_OAs0JGFvWwjnCrBYi::before, .olD_OAs0JGFvWwjnCrBYi::after {
    content: '';
    display: block;
    position: relative;
    width: 36px;
    height: 36px; }
  .olD_OAs0JGFvWwjnCrBYi::before {
    background: white;
    border-radius: 18px 18px 3px 18px;
    transform: rotate(45deg);
    pointer-events: all; }
  .olD_OAs0JGFvWwjnCrBYi::after {
    margin-top: -36px;
    background: url(/build/1e60b0470d13a4ad0e38c7f962ceacc0.svg) 50% 80% no-repeat;
    background-size: 40%; }
  .olD_OAs0JGFvWwjnCrBYi:hover {
    transform: scale(1.1); }
