._2kN1bEkOmNjCcrTn-yXziH {
  display: flex;
  flex-direction: column;
  min-height: 200px;
  overflow: hidden;
  box-shadow: inset 0 0 0 2px #013370;
  border-radius: 5px;
  cursor: pointer;
  transition: opacity 200ms ease; }

._2kN1bEkOmNjCcrTn-yXziH:hover,
._3pOjObaYYkPOsoDxycxSOy {
  opacity: 0.9; }

._1dziXK0tcJDB8gA1N9EKdB {
  flex: 1 0 auto;
  background-size: cover;
  transition: opacity 200ms ease; }

._2tQdAKv_NtDqH-eTCZmMAY {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #013370;
  transition: transform 200ms ease; }
  ._2kN1bEkOmNjCcrTn-yXziH:hover ._2tQdAKv_NtDqH-eTCZmMAY,
  ._3pOjObaYYkPOsoDxycxSOy ._2tQdAKv_NtDqH-eTCZmMAY {
    transform: scale(1.05); }

._1QSfZbQaYZXw9joZdvar_i {
  margin-bottom: 0.5rem;
  font-size: 3rem; }

._14Y2G-X9bQmSnoUB1hIp7 {
  font-size: 1.15rem; }
