._18ZdxrVGjmaGynNinmxVUW {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 36px;
  height: 36px;
  padding: 0 10px;
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.25)) drop-shadow(0 2px 5px rgba(0, 0, 0, 0.15));
  background: white;
  border-radius: 18px;
  cursor: pointer;
  font: 500 16px Quicksand, sans-serif;
  font-weight: 600; }
