html {
  font: 500 16px Quicksand, sans-serif;
  color: #222;
  user-select: none; }

html,
body,
#app {
  height: 100%;
  margin: 0; }

*, *:before, *:after {
  box-sizing: border-box; }
