.V1CHGKqBUPgdjLyP4sA4Q {
  width: 0;
  height: 0;
  margin: 0;
  opacity: 0; }

.fF6JKQWsdLuXCTX7n2oBj {
  display: flex;
  flex-direction: row; }

._3SYFtYYuK8kS6uv18L3ap3 {
  display: flex;
  flex-direction: row;
  padding: 15px 20px 15px 15px;
  margin-right: 15px;
  line-height: 1em;
  background: rgba(34, 34, 34, 0.05);
  border-radius: 5px;
  cursor: pointer;
  transition: background 200ms ease, color 200ms ease; }
  ._3SYFtYYuK8kS6uv18L3ap3:hover {
    background: rgba(34, 34, 34, 0.15); }

._2Fgu5z0pSCsA5IQn7WHR-o { }
  ._2Fgu5z0pSCsA5IQn7WHR-o, ._2Fgu5z0pSCsA5IQn7WHR-o:hover {
    color: white;
    background: #013370; }

._3RBkqzKDcULD4ehBVh2M-l {
  display: flex;
  width: 1em;
  height: 1em;
  margin-right: 10px;
  border-radius: 50%;
  border: 2px solid;
  opacity: 0.8; }
  ._3RBkqzKDcULD4ehBVh2M-l::before {
    content: '';
    flex: 1 0 auto;
    margin: 2px;
    border-radius: 50%;
    background: currentColor;
    opacity: 0;
    transform: scale(0);
    transition: opacity 200ms ease, transform 200ms ease; }

._20AjD9uj2sHOf7PBbh2FUb {
  opacity: 1; }
  ._20AjD9uj2sHOf7PBbh2FUb::before {
    opacity: 1;
    transform: scale(1); }
