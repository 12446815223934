._2HDL1FvwIuWiUjJzMT5DJs { }

.react-datepicker {
  font-family: inherit;
  font-size: 0.8rem;
  border: 2px solid #d9e0ea;
  border-radius: 5px; }
  .react-datepicker__tether-element-attached-top .react-datepicker__triangle {
    top: 1px;
    border-bottom-color: #f2f5f8; }
    .react-datepicker__tether-element-attached-top .react-datepicker__triangle::before {
      top: -3px;
      border-bottom-color: #d9e0ea; }
  .react-datepicker__header {
    background: #f2f5f8;
    border-bottom: 0;
    border-radius: 0; }
  .react-datepicker__day:hover {
    background-color: #e6ebf1; }
  .react-datepicker__day--today {
    color: #013370;
    font-weight: inherit; }
  .react-datepicker__day--keyboard-selected {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #013370;
    color: #013370; }
    .react-datepicker__day--keyboard-selected:hover {
      background-color: rgba(1, 51, 112, 0.1); }
  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
    border-radius: 5px;
    background-color: #013370;
    color: white; }
    .react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover {
      opacity: 0.9;
      background-color: #013370; }
