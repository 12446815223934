._2hZZZlE27e7BZmAwrPJlZU {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%; }

.mAZPJeiAnwzdbvhdKTBDN {
  flex: 0 0 auto; }

._3mBLiVaBWsF7rip806zZo4 {
  flex: auto;
  display: flex; }
