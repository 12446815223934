.p_jScXQdZu6CFbOzkV29T {
  padding: 10px 15px;
  font: inherit;
  border: 2px solid rgba(34, 34, 34, 0.3);
  border-radius: 5px;
  transition: border-color 200ms ease, box-shadow 200ms ease; }
  .p_jScXQdZu6CFbOzkV29T:hover {
    border-color: rgba(34, 34, 34, 0.4); }
  .p_jScXQdZu6CFbOzkV29T:focus {
    outline: none;
    border-color: #013370;
    box-shadow: 0 0 0 2px rgba(1, 51, 112, 0.3); }
