._3ze9hS0-W3ZNxwfUX2NV7d {
  z-index: 10;
  background: white;
  border-radius: 5px;
  filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.25)) drop-shadow(0 1px 5px rgba(0, 0, 0, 0.15));
  max-width: 500px; }
  ._3ze9hS0-W3ZNxwfUX2NV7d[data-placement^='top'] {
    margin-bottom: 8px; }
  ._3ze9hS0-W3ZNxwfUX2NV7d[data-placement^='left'] {
    margin-right: 8px; }
  ._3ze9hS0-W3ZNxwfUX2NV7d[data-placement^='right'] {
    margin-left: 8px; }
  ._3ze9hS0-W3ZNxwfUX2NV7d[data-placement^='bottom'] {
    margin-top: 8px; }
  @media (max-width: 510px) {
    ._3ze9hS0-W3ZNxwfUX2NV7d {
      max-width: calc(100vw - 10px); } }

._3Bpr7iwkVwuakehJmeE3jz {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 15px; }

._26cwNiTrfGMmCSYte-PjY9 {
  max-height: 400px;
  margin: -12px -12px 15px;
  border-radius: 3px;
  max-width: 494px; }
  @media (max-width: 510px) {
    ._26cwNiTrfGMmCSYte-PjY9 {
      max-width: calc(100vw - 16px); } }

._2L0gNGGrHriY1p0M-6vKdl {
  position: absolute;
  width: 0;
  height: 0;
  margin: 15px;
  border: 8px solid transparent; }
  ._3ze9hS0-W3ZNxwfUX2NV7d[data-placement^='top'] ._2L0gNGGrHriY1p0M-6vKdl {
    bottom: -8px;
    margin-top: 0;
    margin-bottom: 0;
    border-top-color: white;
    border-bottom-width: 0; }
  ._3ze9hS0-W3ZNxwfUX2NV7d[data-placement^='left'] ._2L0gNGGrHriY1p0M-6vKdl {
    right: -8px;
    margin-left: 0;
    margin-right: 0;
    border-left-color: white;
    border-right-width: 0; }
  ._3ze9hS0-W3ZNxwfUX2NV7d[data-placement^='right'] ._2L0gNGGrHriY1p0M-6vKdl {
    left: -8px;
    margin-right: 0;
    margin-left: 0;
    border-right-color: white;
    border-left-width: 0; }
  ._3ze9hS0-W3ZNxwfUX2NV7d[data-placement^='bottom'] ._2L0gNGGrHriY1p0M-6vKdl {
    top: -8px;
    margin-bottom: 0;
    margin-top: 0;
    border-bottom-color: white;
    border-top-width: 0; }
