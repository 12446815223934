._3bMN686qBPYP1df9uC_kfN {
  display: flex;
  flex-direction: row;
  height: 60px;
  padding: 0 10px;
  background-color: #013370; }

._1AtfLkFeU7hythTtugN55i {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 500; }

._2VBY1um2Ut7780yfDezLC2 {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  margin: 10px 10px 10px 0;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  box-shadow: inset 0 0 0 2px transparent; }
  ._2VBY1um2Ut7780yfDezLC2:hover {
    background: rgba(255, 255, 255, 0.1); }

._3ftv2FDEFxGlnBtioShVel {
  box-shadow: inset 0 0 0 2px white; }
